<template>
    <div class="societyPage">
        <div class="info-box content">
            <p>优选之选，年轻敢“燃”</p>
            <span
                >加入好生活“千人计划”，2021开启美好</span
            >
        </div>
        <div class="content mar-t-80">
            <lineBox>
                <img
                    src="@/assets/img/talent/workList-title1.png"
                    height="42px"
                    slot="title"
                />
                <div slot="content" class="item-con flex flwr-wr">
                    <cardBox
                        class="item"
                        :item="item"
                        v-for="(item, idx) in worksList"
                        :key="idx"
                    />
                </div>
            </lineBox>
            <!-- <div class="list-box">
                <div class="list-title"></div>
                <ul class="item-box flex"></ul>
            </div> -->
            <lineBox>
                <img
                    src="@/assets/img/talent/workList-title2.png"
                    height="42px"
                    slot="title"
                />
                <div class="item-box flex fl-ai-c fl-ju-c" slot="content">
                    <img
                        src="@/assets/img/talent/workList-map.jpg"
                        width="680px"
                        height="615px"
                    />
                    <div class="text-box fl1">
                        <listBox
                            class=" "
                            v-for="(item, i) in nameList"
                            :key="i"
                        >
                            <div class="list flex">
                                <div>
                                    <p>{{ item.area }}</p>
                                    <span>{{ item.pingy }}</span>
                                    <div>{{ item.name }}</div>
                                </div>
                                <div>
                                    <p>
                                        电话：<span>{{ item.tel }}</span>
                                    </p>
                                    <p>
                                        邮箱：<span>{{ item.email }}</span>
                                    </p>
                                    <p>
                                        地址：<span>{{ item.add }}</span>
                                    </p>
                                </div>
                            </div>
                        </listBox>
                    </div>
                </div>
            </lineBox>
        </div>
    </div>
</template>
<script>
import lineBox from "@/components/talent/lineBox";
import cardBox from "@/components/talent/cardBox";
import listBox from "@/components/talent/listBox";
export default {
  data(){
      return{
           worksList:[
              {title:'区域总经理',info:'负责区域事业部（房产、汽车、金融）整体业务运营管理',icon:'workList-icon1'},
              {title:'城市总监（全国）',info:'统筹管理所在城市房产联动工作，推广及输出优质的新房/二手房产品与资源',icon:'workList-icon2'},
              {title:'市场拓展',info:'开发商及项目拜访，独立进行提案及商务谈判，完成项目签约',icon:'workList-icon3'},
              {title:'经服（渠道）',info:'将项目推广至门店，促进一二手房经纪公司联动，协助项目完成工作对接',icon:'workList-icon4'},
              {title:'驻场运营',info:'完成进场项目的营销管理工作，包括业绩确认、关系维护、项目数据分析整理',icon:'workList-icon5'},
          ],
          nameList:[
              {name:'隆凤',pingy:'Northern China',area:'华北区域',tel:'13810838710',email:'190931@lifeat.cn',add:'北京市朝阳区广渠路百环大厦310'},
              {name:'黄兴',pingy:'Western China',area:'西部区域',tel:'18980713061',email:'190664@lifeat.cn',add:'成都市温江路光华大道东三段珠江悦湖 国际售楼部'},
              {name:'伏雅璐',pingy:'Eastern China',area:'华东区域',tel:'13611965688',email:'190340@lifeat.cn',add:'上海市静安区灵石路695号14楼1405A室'},
              {name:'杨娟',pingy:'Central China',area:'华中区域',tel:'13886299010',email:'190986@lifeat.cn',add:'武汉市洪山区徐东大街85号中冶南方大 厦A801'},
              {name:'黄文丽',pingy:'Southern China',area:'华南区域',tel:'020-88523756',email:'190322@lifeat.cn',add:'广州市天河区冼村路11号之二保利威座 北塔写字楼第39层3904室'},
          ],
      }
  },
  components: {
      lineBox,
      cardBox,
      listBox
     // elSwitch:Switch
  },
}
</script>
<style lang="scss" scoped>
.societyPage {
    .info-box {
        > p {
            font-size: 40px;
            font-weight: bold;
            line-height: 52px;
            letter-spacing: 1px;
            margin-bottom: 12px;
        }
        > span {
            font-size: 18px;
            color: #002062;
            line-height: 30px;
        }
    }
    .item-con {
        padding: 26px 0px 100px 63px;
        > .item {
            margin-right: 52px;
            &:nth-child(1) {
                margin-top: 86px;
                width: 347px;
            }
            &:nth-child(2) {
                width: 335px;
            }
            &:nth-child(3) {
                margin: 0;
                margin-top: 35px;
                width: 329px;
            }
            &:nth-child(4) {
                width: 347px;
                margin-top: 30px;
                margin-left: 125px;
            }
            &:nth-child(5) {
                margin-top: -7px;
                width: 335px;
            }
        }
    }
    .text-box {
        .listBox {
            & + .listBox {
                margin-top: 24px;
            }
            .list {
                > div:first-child {
                    width: 90px;
                    margin-right: 20px;
                    > p {
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 24px;
                        letter-spacing: 2px;
                    }
                    > span {
                        font-size: 12px;
                        font-weight: 400;
                        color: #6679a1;
                        line-height: 17px;
                    }
                    > div {
                        font-size: 22px;
                        font-weight: bold;
                        line-height: 29px;
                        margin-top: 6px;
                    }
                }
                > div:last-child {
                    line-height: 24px;
                    font-size: 14px;

                    span {
                        display: inline-block;
                        width: 250px;
                        vertical-align: top;
                    }
                }
            }
        }
    }
    .item-box {
        padding: 6px 0 20px 33px;
    }
}
</style>
