 <template>
    <div class="flex listBox">
        <slot></slot>
    </div>
</template>
<script>
 
export default {
     props:{
         item: {
            type: Object,
            default:()=>{
                return {}
            }
         }
    },
  data(){
      return{
          
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.listBox {
    align-items: center;
    width: 461px;
    //min-height: 122px;
    background: #ffffff;
 
box-shadow: 0px 0px 24px 0px rgba(186, 196, 228, 0.26);
    border-radius: 100px 80px 2px 100px;
    border: 1px solid #FE7A47 ;
    border-width: 0 5px 0 0;
    padding: 17px 0;
    padding-left: 40px;
}
</style>
