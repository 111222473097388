 <template>
    <div class="cardBox">
        <img
            v-if="item.icon"
            :src="require('@/assets/img/talent/' + item.icon + '.png')"
        />
        <p>{{ item.title }}</p>
        <span>{{ item.info }}</span>
    </div>
</template>
<script>
 
export default {
    props:{
         item: {
            type: Object,
            default:()=>{
                return {}
            }
         }
    },
    // created(){
    //     this.item = this.item ||{}
    // },
  data(){
      return{
          
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.cardBox {
    width: 347px;
    max-height: 210px;
    min-width: 189px;
    background: url("~@/assets/img/talent/item-bg-icon.png") no-repeat 84% 90%
        #ffffff;
    background-size: 37px;
    border-radius: 4px;
    position: relative;
    padding: 32px 41px 48px 48px;
    box-shadow: 0px 0px 24px 0px rgba(186, 196, 228, 0.26);

    > img {
        width: 54px;
        position: absolute;
        top: 20px;
        left: -27px;
    }
    > p {
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        margin-bottom: 16px;
    }
    > span {
        font-size: 18px;
        line-height: 28px;
    }
}
</style>
