<template>
    <div class="lineBox">
        <div class="line-list-title">
            <slot name="title"></slot>
        </div>
        <div class="line-item-box">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
          
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.lineBox {
    .line-item-box {
        flex-wrap: wrap;
        position: relative;
        margin: 10px 0;
        padding-left: 21px;

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 21px;
            background: #FE7A47;
        }
    }
}
</style>
