    <!-- 人才招募-->

<template>
    <div class="Talent">
        <div class="banner-bg">
            <div class="banner-box min-w">
                <!-- <div class="content text-po po-r">
                <p>星河璀璨，在优选好生活的职海徜徉 占据最耀眼的华章</p>
                <div class="flex swich-box po-a" :class="{ open: tabSwatch }">
                    <div @click="tabSwatch = false">社会招聘</div>
                    <div @click="tabSwatch = true">校园招聘</div>
                </div>
            </div> -->
            </div>
        </div>

        <societyPage></societyPage>
        <!-- <transition name="fade">
            <component
                :is="tabSwatch ? 'schoolPage' : 'societyPage'"
            ></component>
        </transition> -->
    </div>
</template>

<script>
import societyPage from "@/components/talent/societyPage";
//import schoolPage from "@/components/talent/schoolPage";
export default {
  name: 'Talent',
  components: {
     societyPage,
     //schoolPage
  },
  data(){
      return{
          tabSwatch:false,
      }
  },
}
</script>
<style lang="scss" scoped>
.banner-bg {
    background: #b70002;
}
.banner-box {
    height: 655px;
    background: url("~@/assets/img/talent/talent-banner.jpg") no-repeat center;
    background-size: cover;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 60px;
}
.text-po {
    padding-top: 277px;
    height: 100%;
    > p {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 2px;
        width: 490px;
        padding-left: 4px;
    }
}
.swich-box {
    background: #fff;
    width: 446px;
    height: 92px;
    align-items: center;
    padding: 6px;
    border-radius: 46px;
    color: #4898ff;
    bottom: 66px;
    box-shadow: 0px 12px 40px 0px rgba(72, 152, 255, 0.22);

    > div {
        width: 50%;
        text-align: center;
        position: relative;
        font-size: 26px;
        font-weight: 500;
        line-height: 37px;
        letter-spacing: 1px;
        transition: all 0.3s;
        cursor: pointer;
        &:first-child {
            color: #fff;
        }
    }
    &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 80px;
        background: #4898ff;
        border-radius: 40px;

        transition: all 0.3s;
    }
    &.open {
        > div {
            color: #4898ff;
            &:last-child {
                color: #fff;
            }
        }
        &::before {
            transform: translateX(210px);
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
